// ClearpoolYields.js
import React from 'react';

function ClearpoolYields({ clearpoolYields }) {
    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Borrower</th>
                        <th>Yield</th>
                        <th>Pool Size</th>
                        <th>Utilization</th>
                        <th>Last Updated</th>
                    </tr>
                </thead>
                <tbody>
                    {clearpoolYields.map((item, index) => (
                        <tr key={index}>
                            <td>{item.borrower}</td>
                            <td>{item.yield}</td>
                            <td>
                                {typeof item.poolSize === 'number' ? item.poolSize.toFixed(0) : item.poolSize}
                            </td>
                            <td>{item.utilization}</td>
                            <td>{item.lastUpdated}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ClearpoolYields;
