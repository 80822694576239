import React, { useState, useEffect } from 'react';
import ClearpoolYields from './components/ClearpoolYields';
import DefiYield from './components/DefiYield';
import SpotFutures from './components/SpotFutures';
import MarketUpdates from './components/MarketUpdates';
import Wincent from './components/Wincent';
import NickelData from './components/NickelData';
import PasswordProtection from './components/PasswordProtection';
import LoanBook from './components/LoanBook';
import ADOC from './components/ADOC';
import './App.css';

const formatCurrency = (value) => {
  return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

const formatPercentage = (value) => {
  return `${(value * 100).toFixed(2)}%`;
};

function App() {
  const [activeTab, setActiveTab] = useState('Market Updates');
  const [passwordEntered, setPasswordEntered] = useState(false);
  const [marketPrices, setMarketPrices] = useState([]);
  const [spotFutures, setSpotFutures] = useState([]);
  const [clearpoolYields, setClearpoolYields] = useState([]);
  const [defiYield, setDefiYield] = useState([]);
  const [wincentData, setWincentData] = useState([]);
  const [nickelData, setNickelData] = useState([]);
  const [loanBook, setLoanBook] = useState([]);
  const [adocStats, setAdocStats] = useState([]);
  const [loanBookError, setLoanBookError] = useState(null);
  const [marketPricesError, setMarketPricesError] = useState(null);
  const [spotFuturesError, setSpotFuturesError] = useState(null);
  const [clearpoolYieldsError, setClearpoolYieldsError] = useState(null);
  const [defiYieldError, setDefiYieldError] = useState(null);
  const [wincentDataError, setWincentDataError] = useState(null);
  const [nickelDataError, setNickelDataError] = useState(null);
  const [adocStatsError, setAdocStatsError] = useState(null);
  const [displayCount, setDisplayCount] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  const oneHourInMilliseconds = 3600000;
  const initialLoadDelay = 0; // 0 seconds in milliseconds

  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      if (passwordEntered) {
        fetchData();
      }
    }, 30000); // 30 seconds in milliseconds

    // Initial load after 30 seconds
    setTimeout(() => {
      if (passwordEntered) {
        fetchData();
      }
    }, initialLoadDelay);

    return () => clearInterval(fetchDataInterval);
  }, [passwordEntered]);

  // Check if the password has been entered within the last hour
  useEffect(() => {
    const lastPasswordEntryTimestamp = localStorage.getItem('passwordEntryTimestamp');
    const currentTime = new Date().getTime();

    if (lastPasswordEntryTimestamp && currentTime - lastPasswordEntryTimestamp < oneHourInMilliseconds) {
      setPasswordEntered(true);
    }
  }, []);

  const handlePasswordEntry = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem('passwordEntryTimestamp', currentTime);
    setPasswordEntered(true);
  };

  // Fetch ADOC Stats data
  const fetchAdocStats = () => {
    const url = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/dashboard/adoCstats';
    const bearerToken = '49*Z6XA2fqAd9dIC*UPUaeJd';
    return fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        setAdocStats(json.adoCstats || []);
      })
      .catch(error => {
        console.error('Error fetching ADOC Stats data: ', error);
        setAdocStatsError(error);
      });
  };

  // Fetch Market Updates data
  const fetchMarketUpdates = () => {
    const marketPricesUrl = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/marketrecap/marketPrices';
    return fetch(marketPricesUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        setMarketPrices(json.marketPrices || []);
      })
      .catch(error => {
        console.error('Error fetching Market Prices: ', error);
        setMarketPricesError(error);
      });
  };

  // Fetch Spot Futures data
  const fetchSpotFutures = () => {
    const spotFuturesUrl = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/marketrecap/spotfutures';
    return fetch(spotFuturesUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        setSpotFutures(json.spotfutures || []);
      })
      .catch(error => {
        console.error('Error fetching Spot Futures data: ', error);
        setSpotFuturesError(error);
      });
  };

  // Fetch Clearpool Yields data
  const fetchClearpoolYields = () => {
    const url = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/marketrecap/clearpoolYield';
    return fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        const clearpoolYieldsData = json.clearpoolYield || [];
        const modifiedClearpoolYields = clearpoolYieldsData.map(item => ({
          borrower: item.borrower,
          poolSize: formatCurrency(item.poolSize),
          utilization: formatPercentage(item.utilization),
          yield: formatPercentage(item.yield),
          lastUpdated: item.lastUpdated, // Add the lastUpdated field
        }));
        setClearpoolYields(modifiedClearpoolYields);
      })
      .catch(error => {
        console.error('Error fetching Clearpool Yields data: ', error);
        setClearpoolYieldsError(error);
      });
  };

  // Fetch DeFi Yield data
  const fetchDefiYield = () => {
    const url = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/marketrecap/deFiYield';
    return fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        setDefiYield(json.deFiYield || []);
      })
      .catch(error => {
        console.error('Error fetching DeFi Yield data: ', error);
        setDefiYieldError(error);
      });
  };

  // Fetch Wincent data
  const fetchWincentData = () => {
    const url = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/dashboard/wincent';
    const bearerToken = '49*Z6XA2fqAd9dIC*UPUaeJd';
    return fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        setWincentData(json.wincent || []);
      })
      .catch(error => {
        console.error('Error fetching Wincent data: ', error);
        setWincentDataError(error);
      });
  };

  // Fetch Nickel data
  const fetchNickelData = () => {
    const url = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/dashboard/nickel';
    const bearerToken = '49*Z6XA2fqAd9dIC*UPUaeJd';
    return fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        setNickelData(json.nickel || []);
      })
      .catch(error => {
        console.error('Error fetching Nickel data: ', error);
        setNickelDataError(error);
      });
  };

  // Fetch Loan Book data
  const fetchLoanBook = () => {
    const url = 'https://api.sheety.co/cd8d5925e10bc14b1b986b20a83c50f0/dashboard/loanBook';
    const bearerToken = '49*Z6XA2fqAd9dIC*UPUaeJd';
    return fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        const loanBookData = json.loanBook || [];
        const formattedLoanBook = loanBookData.map(item => ({
          loanNo: item.loanNo,
          loanAmount: formatCurrency(item.loanAmount),
          currency: item.currency,
          interestRate: formatPercentage(item['interestRate (%)']),
          navDate: item.navDate,
          interestDue: formatCurrency(item.interestDue),
          id: item.id,
        }));
        setLoanBook(formattedLoanBook);
      })
      .catch(error => {
        console.error('Error fetching Loan Book data: ', error);
        setLoanBookError(error);
      });
  };

  // Fetch all data
  const fetchData = async () => {
    try {
      const responses = await Promise.all([
        fetchMarketUpdates(),
        fetchSpotFutures(),
        fetchClearpoolYields(),
        fetchDefiYield(),
        fetchNickelData(),
        fetchWincentData(),
        fetchLoanBook(),
        fetchAdocStats(),
      ]);

      const data = await Promise.all(responses.map((response) => response.json()));

      // Set data for each state
      setMarketPrices(data[0].marketPrices || []);
      setSpotFutures(data[1].spotfutures || []);
      setClearpoolYields(data[2].clearpoolYield || []);
      setDefiYield(data[3].deFiYield || []);
      setWincentData(data[4].wincent || []);
      setNickelData(data[5].nickel || []);
      setLoanBook(data[6].loanBook || []);
      setAdocStats(data[7].adoCstats || []);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setIsLoading(false);
    }
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'Market Updates':
        return <MarketUpdates marketPrices={marketPrices} />;
      case 'Spot Futures':
        return <SpotFutures spotFutures={spotFutures} />;
      case 'Clearpool Yields':
        return <ClearpoolYields clearpoolYields={clearpoolYields} />;
      case 'DeFi Yield':
        return <DefiYield defiYield={defiYield} />;
      case 'Wincent':
        return (
          <Wincent
            wincentData={wincentData}
            displayCount={displayCount}
            setDisplayCount={setDisplayCount}
          />
        );
      case 'Nickel':
        return <NickelData nickelData={nickelData} />;
      case 'Loan Book':
        return <LoanBook loanBook={loanBook} />;
      case 'ADOC':
        return <ADOC adocStats={adocStats} />;
      default:
        return null;
    }
  };

  return (
    <div className="App">
      {!passwordEntered ? (
        <PasswordProtection onPasswordEntry={handlePasswordEntry} />
      ) : (
        <div>
          <div className="sidebar">
            <ul>
              <li className="sidebar-button" onClick={() => setActiveTab('Market Updates')}>
                Market Updates
              </li>
              <li className="sidebar-button" onClick={() => setActiveTab('Spot Futures')}>
                Spot Futures
              </li>
              <li className="sidebar-button" onClick={() => setActiveTab('Clearpool Yields')}>
                Clearpool Yields
              </li>
              <li className="sidebar-button" onClick={() => setActiveTab('DeFi Yield')}>
                DeFi Yield
              </li>
              <li className="sidebar-button" onClick={() => setActiveTab('Wincent')}>
                Wincent
              </li>
              <li className="sidebar-button" onClick={() => setActiveTab('Nickel')}>
                Nickel
              </li>
              <li className="sidebar-button" onClick={() => setActiveTab('Loan Book')}>
                Loan Book
              </li>
              <li className="sidebar-button" onClick={() => setActiveTab('ADOC')}>
                ADOC
              </li>
            </ul>
          </div>
          <div className="main-content">
            {renderActiveTab()}
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
